<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="row">
                    <div class="col">

                    </div>
                    <div class="col">

                    </div>
                    <div class="col-auto">
                        <font-awesome-icon icon="fa-solid fa-right-from-bracket" @click="logout()" />
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col text-center">
                <div>
                    <img class="img-fluid rounded-circle" style="max-width: 120px;" :src="profile.avatar" alt="">
                </div>
                <div class="mt-4 font-weight-bold" style="font-size: 20px;">
                    {{profile.name}}
                </div>
                <div class="mt-2">
                    <div>
                        {{profile.email}}
                    </div>
                    <div>
                        {{profile.phone_number}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <div class="row">
                    <div class="col font-weight-bold">
                        Informasi Donatur
                    </div>
                    <div class="col-auto">
                        <font-awesome-icon icon="fa-solid fa-pencil-alt" />
                    </div>
                </div>

                <div class="row mt-4 text-muted">
                    <div class="col">
                        Total Donasi
                    </div>
                    <div class="col-auto">
                        Rp. {{ profile.total_donation.toLocaleString('id') }}
                    </div>
                </div>

                <div class="row mt-2 text-muted">
                    <div class="col">
                        Tanggal Terdaftar
                    </div>
                    <div class="col-auto">
                        {{ profile.created_at }}
                    </div>
                </div>

                <div class="row mt-2 text-muted">
                    <div class="col">
                        Status Akun
                    </div>
                    <div class="col-auto">
                        {{ profile.status }}
                    </div>
                </div>

                <hr>

                <div class="row mt-2 text-muted">
                    <div class="col">
                        Tanggal Terdaftar
                    </div>
                    <div class="col-auto font-weight-bold">
                        Lihat
                    </div>
                </div>
            </div>
        </div>
        <NavbarComponent />
    </div>
</template>

<script>
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import NavbarComponent from '../../components/v2/NavbarComponent.vue';
import axios from 'axios';

library.add(faRightFromBracket, faPencilAlt)

export default {
    name: 'Profile',
    components: {
        NavbarComponent,
    },

    data() {
        return {
            profile: {
                id: null,
                name: null,
                email: null,
                total_donation: 0,
                created_at: null,
                status: null,
            }
        }
    },

    beforeMount() {
        this.getProfile();
    },

    methods: {
        logout() {
            localStorage.removeItem('token');
            this.$router.replace('/')
        },

        getProfile() {
            axios.get(process.env.VUE_APP_API + 'profile', {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            }).then((resp) => {
                if(resp.status == 200) {
                    const data = resp.data.data;

                    this.profile.id = data.id;
                    this.profile.email = data.email;
                    this.profile.name = data.name;
                    this.profile.avatar = data.avatar;
                    this.profile.phone_number = data.phone_number;
                    this.profile.total_donation = data.total_donation;
                    this.profile.created_at = data.created_at;
                    this.profile.status = data.status;
                }
            });
        },
    }
}

</script>